<template>
  <v-app>
    <v-main>
      <template v-if="user !== null && !isLoading">
        <div class="campaign-rewards-creators-web" :style="'height: ' + (screenHeight < 900 ? (!toggleOpen && filteredCoupons.length > 3 ? filteredCoupons.length * 154 + 450 : 1000) : 2292) + 'px !important'">
          <div class="bg-main"></div>
          <div class="bg-open"></div>
          <div class="content">
            <div class="header">
              <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo" src="/imgs/twitch-logo.png" /></a>
              <div class="creator-photo">
                <div class="photo">
                  <v-tooltip bottom color="#9146ff" v-if="user !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar style="cursor: pointer" @click="logout" class="ml-2" v-bind="attrs" v-on="on">
                        <v-img :src="user?.profile_image_url"></v-img>
                      </v-avatar>
                    </template>
                    <span class="logout">{{ $vuetify.lang.t('$vuetify.logout') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <template v-if="user !== null">
                <div class="creator-username" v-text="user?.display_name"></div>
              </template>
            </div>
            <div class="back-container" @click="viewDashboard">
              <svg class="back-button" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#B2E816" />
                <path d="M38 21L27 32L38 43" stroke="black" stroke-width="6" stroke-linecap="round" />
              </svg>
            </div>
            <div class="back-container-mobile" @click="viewDashboard">
              <svg class="back-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31px" height="31px" viewBox="0 0 33 33" version="1.1">
                <g id="surface1">
                  <path style="stroke: none; fill-rule: nonzero; fill: rgb(69.803922%, 90.980392%, 8.627451%); fill-opacity: 1" d="M 16.5 0 C 25.613281 0 33 7.386719 33 16.5 C 33 25.613281 25.613281 33 16.5 33 C 7.386719 33 0 25.613281 0 16.5 C 0 7.386719 7.386719 0 16.5 0 Z M 16.5 0 " />
                  <path style="fill: none; stroke-width: 6; stroke-linecap: round; stroke-linejoin: miter; stroke: rgb(0%, 0%, 0%); stroke-opacity: 1; stroke-miterlimit: 4" d="M 38.001302 20.997277 L 26.998698 31.999882 L 38.001302 43.002486 " transform="matrix(0.507692,0,0,0.507692,0,0)" />
                </g>
              </svg>
            </div>
            <div class="welcome">{{ score?.campaign?.name }}{{ ' ' }}{{ $vuetify.lang.t('$vuetify.campaign_rewards') }}</div>
            <div class="search-box">
              <div class="search-rewards">{{ $vuetify.lang.t('$vuetify.search_rewards') }}</div>
              <div class="search-bg">
                <input type="text" class="search-text" v-model="couponsSearch" />
              </div>
              <svg class="search-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.7883 21.9095C18.0354 23.2224 15.8585 24 13.5 24C7.70101 24 3 19.299 3 13.5C3 7.70101 7.70101 3 13.5 3C19.299 3 24 7.70101 24 13.5C24 15.8585 23.2224 18.0353 21.9096 19.7882L26.5608 24.4394L24.4395 26.5607L19.7883 21.9095ZM21 13.5C21 17.6421 17.6421 21 13.5 21C9.35786 21 6 17.6421 6 13.5C6 9.35786 9.35786 6 13.5 6C17.6421 6 21 9.35786 21 13.5Z"
                  fill="#D2D2E6"
                />
              </svg>
            </div>
            <div class="search-box-mobile">
              <div class="search-rewards-mobile">{{ $vuetify.lang.t('$vuetify.search_rewards') }}</div>
              <div class="search-bg-mobile">
                <input type="text" class="search-text-mobile" v-model="couponsSearch" />
              </div>
              <svg class="search" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.89415 10.9548C9.01771 11.6112 7.92926 12 6.75 12C3.8505 12 1.5 9.64949 1.5 6.75C1.5 3.8505 3.8505 1.5 6.75 1.5C9.64949 1.5 12 3.8505 12 6.75C12 7.92924 11.6112 9.01767 10.9548 9.8941L13.2804 12.2197L12.2197 13.2803L9.89415 10.9548ZM10.5 6.75C10.5 8.82107 8.82107 10.5 6.75 10.5C4.67893 10.5 3 8.82107 3 6.75C3 4.67893 4.67893 3 6.75 3C8.82107 3 10.5 4.67893 10.5 6.75Z"
                  fill="#D2D2E6"
                />
              </svg>
            </div>
            <div class="rewards-page-box">
              <div class="rewards-per-page">{{ $vuetify.lang.t('$vuetify.rewards_per_page') }}</div>
              <div class="page-bg">
                <input type="number" class="page-text" v-model="couponsPerPage" min="1" max="10" onKeyUp="if(this.value>10){this.value=10;}else if(this.value<0){this.value=0;}" />
              </div>
            </div>
            <div class="rewards-page-box-mobile">
              <div class="rewards-per-part">{{ $vuetify.lang.t('$vuetify.rewards_per_page') }}</div>
              <div class="page-bg-mobile">
                <input type="number" class="page-text-mobile" v-model="couponsPerPage" min="1" max="10" onKeyUp="if(this.value>10){this.value=10;}else if(this.value<0){this.value=0;}" />
              </div>
            </div>
            <div class="rewards-table">
              <template v-if="!filteredCoupons.length">
                <div class="rewards-no-data">{{ $vuetify.lang.t('$vuetify.no_rewards_available') }}</div>
              </template>
              <template v-else>
                <div>
                  <div class="tier">{{ $vuetify.lang.t('$vuetify.tier') }}</div>
                  <div class="coupon-code">{{ $vuetify.lang.t('$vuetify.coupon_code') }}</div>
                  <div class="digital-reward-name">{{ $vuetify.lang.t('$vuetify.digital_reward_name') }}</div>
                  <div class="digital-reward-mechanics">{{ $vuetify.lang.t('$vuetify.digital_reward_mechanics') }}</div>
                </div>
                <template v-for="(coupon, index) in filteredCoupons">
                  <div :key="index">
                    <div :class="'row-bg-' + index"></div>
                    <div :class="'row-tier-' + index">{{ coupon.tier_level }}</div>
                    <template v-if="coupon.code.length > 18">
                      <v-tooltip bottom color="#9146ff">
                        <template v-slot:activator="{ on, attrs }">
                          <div :class="'text-truncate row-code-' + index" style="max-width: 250px" v-bind="attrs" v-on="on">{{ coupon.code }}</div>
                        </template>
                        <span class="logout">{{ coupon.code }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <div :class="'text-truncate row-code-' + index" style="max-width: 250px">{{ coupon.code }}</div>
                    </template>
                    <template v-if="coupon.name.length > 18">
                      <v-tooltip bottom color="#9146ff">
                        <template v-slot:activator="{ on, attrs }">
                          <div :class="'text-truncate row-name-' + index" style="max-width: 250px" v-bind="attrs" v-on="on">{{ coupon.name }}</div>
                        </template>
                        <span class="logout">{{ coupon.name }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <div :class="'text-truncate row-name-' + index" style="max-width: 250px">{{ coupon.name }}</div>
                    </template>
                    <template v-if="coupon.mechanics.length > 20">
                      <v-tooltip bottom color="#9146ff">
                        <template v-slot:activator="{ on, attrs }">
                          <div :class="'text-truncate row-mechanics-' + index" style="max-width: 320px" v-bind="attrs" v-on="on">{{ coupon.mechanics }}</div>
                        </template>
                        <span class="logout">{{ coupon.mechanics }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <div :class="'text-truncate row-mechanics-' + index" style="max-width: 320px">{{ coupon.mechanics }}</div>
                    </template>
                  </div>
                </template>
                <div @click="download">
                  <div class="download-button" :style="'top: ' + ((filteredCoupons.length || 1) * 130 + 620) + 'px !important'">
                    <div class="download-text">{{ $vuetify.lang.t('$vuetify.download_rewards') }}</div>
                  </div>
                </div>
                <div class="pagination" :style="'top: ' + ((filteredCoupons.length || 1) * 130 + 660) + 'px !important'">
                  <v-pagination v-model="couponsPage" :length="numberOfPages" color="#424242"></v-pagination>
                </div>
              </template>
            </div>
            <div class="rewards-table-mobile">
              <template v-if="!filteredCoupons.length">
                <div class="rewards-no-data">{{ $vuetify.lang.t('$vuetify.no_rewards_available') }}</div>
              </template>
              <template v-else>
                <template v-for="(coupon, index) in filteredCoupons">
                  <div class="row-mobile" :key="index" :style="'top: ' + index * 154 + 'px !important'">
                    <div class="row-bg-mobile"></div>
                    <div class="row-container-mobile">
                      <div class="row-tier">{{ $vuetify.lang.t('$vuetify.tier') }}</div>
                      <div class="row-coupon-code">{{ $vuetify.lang.t('$vuetify.coupon_code') }}</div>
                      <div class="row-reward-name">{{ $vuetify.lang.t('$vuetify.digital_reward_name') }}</div>
                      <div class="row-rewards-mechanics" v-html="$vuetify.lang.t('$vuetify.digital_mechanics')"></div>
                      <div class="row-tier-value">{{ coupon.tier_level }}</div>
                      <div class="row-code-value">{{ coupon.code }}</div>
                      <div class="row-name-value">{{ coupon.name }}</div>
                      <template v-if="coupon.mechanics.length > 20">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="text-truncate row-mechanics-value" style="max-width: 120px" v-bind="attrs" v-on="on">{{ coupon.mechanics }}</div>
                          </template>
                          <span class="logout">{{ coupon.mechanics }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div class="row-mechanics-value">{{ coupon.mechanics }}</div>
                      </template>
                    </div>
                  </div>
                </template>
                <div @click="download">
                  <div class="download-button" :style="'top: ' + ((filteredCoupons.length || 1) * 154 + 230) + 'px !important'">
                    <div class="download-text">{{ $vuetify.lang.t('$vuetify.download_rewards') }}</div>
                  </div>
                </div>
                <div class="pagination" :style="'top: ' + ((filteredCoupons.length || 1) * 154 + 270) + 'px !important'">
                  <v-pagination v-model="couponsPage" :length="numberOfPages" color="#424242"></v-pagination>
                </div>
              </template>
            </div>
            <img class="red-bits-rewards" src="/imgs/red-bits-rewards.png" />
            <div class="footer">
              <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
              <div>
                <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
              </div>
              <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
              <div>
                <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
              </div>
              <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <v-overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sortBy } from 'lodash'

export default {
  data() {
    return {
      showMenu: false,
      link: process.env.VUE_APP_BASE_API_URL,
      score: null,
      campaignTabs: 0,
      isLoading: false,
      coupons: [],
      couponsSearch: '',
      couponsPage: 1,
      couponsPerPage: 10,
      screenHeight: 0,
      screenWidth: 0
    }
  },
  watch: {
    couponsPerPage(newValue) {
      this.couponsPage = 1
    }
  },
  computed: {
    ...mapGetters(['user', 'scores']),
    filteredCoupons() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.slice((this.couponsPage - 1) * this.couponsPerPage, this.couponsPage * this.couponsPerPage) ?? 0
    },
    numberOfPages() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.length < this.couponsPerPage ? 1 : Math.ceil(sorted.length / this.couponsPerPage)
    }
  },
  methods: {
    ...mapActions(['loadSystem', 'getUserCampaignCoupons', 'signOut']),
    viewDashboard() {
      this.$router.push({ name: 'dashboard' })
    },
    logout() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    download() {
      window.open(`${this.link}api/Coupon/Campaign/${this.score?.campaign?.id}/MyCoupons/${this.user?.id}/Export`)
    },
    onScreenResize() {
      window.addEventListener('resize', () => {
        this.updateScreenHeight()
        this.updateScreenWidth()
      })
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.updateScreenHeight()
    this.updateScreenWidth()
    this.onScreenResize()
    this.$vuetify.lang.current = localStorage.language ?? 'en'
    if (this.user == null) {
      this.isLoading = true
      this.loadSystem().then((_) => {
        this.getUserCampaignCoupons(this.$route.params.campaignId).then((result) => {
          this.coupons = result
          this.score = this.scores.find((s) => s.campaign.id === this.$route.params.campaignId)
          this.isLoading = false
        })
      })
    } else if (!this.coupons.length) {
      this.isLoading = true
      this.getUserCampaignCoupons(this.$route.params.campaignId).then((result) => {
        this.coupons = result
        this.score = this.scores.find((s) => s.campaign.id === this.$route.params.campaignId)
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>

<style lang="scss" scoped>
.campaign-rewards-creators-web,
.campaign-rewards-creators-web * {
  box-sizing: border-box;
}
.campaign-rewards-creators-web {
  background: #000000 !important;
  width: 100% !important;
  height: 2292px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-main {
  background: linear-gradient(180deg, #e3ff93 0%, #c1ff0c 19.27%);
  width: 100%;
  height: 1074px;
  position: absolute;
  left: 0.46px;
  top: -3px;
  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1234px;
  position: absolute;
  left: 0px;
  top: 305px;
  @media only screen and (max-height: 900px) {
    background: #000000;
    border-radius: 30px 30px 0px 0px;
    width: 393px;
    height: 844px;
    position: absolute;
    left: 0px;
    top: 157px;
  }
}
.header {
  position: absolute;
  inset: 0;
}
.creator-photo {
  width: 42px;
  height: 42px;
  position: static;
}
.photo {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 70px;
  top: 60px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 44px;
    top: 44px;
    z-index: 1;
  }
}
.logout {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-height: 900px) {
    z-index: 1;
  }
}
.creator-username {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  position: absolute;
  right: 130px;
  top: 63px;
  width: 372px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 600 14px/18px 'RoobertRegular', sans-serif;
    position: absolute;
    right: 70px;
    top: 64px;
    width: 124px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.twitch-logo {
  width: 131px;
  height: 44px;
  position: absolute;
  left: 99.46px;
  top: 61px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}
.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-height: 900px) {
    max-height: 900px;
  }
}
.back-container {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.back-container-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.back-button {
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  left: calc(50% - 730px);
  top: 180px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    left: -4px;
    top: 24px;
  }
}
.back-button:hover {
  rect {
    fill: #7d5bbe;
  }
}
.welcome {
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 106px;
  position: absolute;
  left: calc(50% - 623.54px);
  top: 167.4px;

  @media only screen and (max-height: 900px) {
    color: #000000;
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 43px;
    top: 94px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.search-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.search-rewards-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 43px;
  top: 170px;
  width: 168px;
  height: 18px;
}
.search-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 94px;
  height: 20px;
  position: absolute;
  left: 43px;
  top: 191px;
}
.search-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 11px/23.52px 'RoobertRegular', sans-serif;
  margin-left: 20px;
  width: 70px;
  z-index: 100 !important;
  position: absolute;
  top: -3px;
}
.search {
  position: absolute;
  left: 47px;
  top: 194px;
  overflow: visible;
}
.search-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.search-rewards {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 630px);
  top: 388px;
  width: 351px;
}
.search-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 419px;
  height: 50px;
  position: absolute;
  left: calc(50% - 630px);
  top: 427px;
  z-index: 1;
}
.search-icon {
  position: absolute;
  left: calc(50% - 621px);
  top: 437px;
  overflow: visible;
  z-index: 1;
}
.search-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 50px;
  margin-top: -10px;
  width: 350px;
  z-index: 100 !important;
}
.rewards-page-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.rewards-per-page {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 430px);
  top: 388px;
  width: 302px;
}
.page-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 202px;
  height: 50px;
  position: absolute;
  left: calc(50% + 430px);
  top: 427px;
  z-index: 1;
}
.page-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 10px;
  width: 202px;
  height: 47px;
}
.rewards-page-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;

  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.rewards-per-part {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: right;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 185px;
  top: 170px;
  width: 163px;
  height: 19px;
}
.page-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 93px;
  height: 20px;
  position: absolute;
  left: 255px;
  top: 191px;
}
.page-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 12px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 4px;
  top: -4px;
  width: 86px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
}
.download-button {
  box-sizing: border-box;
  background: #beff00;
  border-radius: 326.53px;
  padding: 14px 30px 14px 30px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 480px;
  left: calc(50% - 630px);
  top: 404px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    background: #beff00;
    border-radius: 326.53px;
    padding: 1px 10px 1px 10px;
    display: flex;
    flex-direction: column;
    gap: 42.45px;
    align-items: center;
    justify-content: center;
    height: 25px;
    left: 44px;
    width: 200px;
  }
}
.download-button:hover {
  background: #7d5bbe;
}
.download-text {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 500 14px/42.45px 'RoobertRegular', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer {
  position: absolute;
  inset: 0;
}
.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2042px;

  @media only screen and (max-height: 900px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: unset;
    bottom: 104px !important;
  }
}
.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2190px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2190px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: unset;
    bottom: 36px !important;
    min-width: 200px;
    max-width: 220px;
  }
}
.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2190px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 36px !important;
  }
}
.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2154px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.red-bits-rewards {
  width: 767.91px;
  height: 785px;
  position: absolute;
  right: -300px;
  top: 1560px;
  transform-origin: 0 0;
  transform: rotate(-1.9deg) scale(1, 1);

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
/* ----------------------- */
/*      Rewards Table      */
/* ----------------------- */
.rewards-table {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.pagination {
  position: relative;
  margin: auto;
  width: 50%;
  padding-left: 50px;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    position: absolute;
    left: 76px;
  }
}
.tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 533px;
  width: 169px;
}
.coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 533px;
  width: 269px;
}
.digital-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 533px;
  width: 236px;
}
.digital-reward-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 533px;
  width: 262px;
}
.rewards-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 696px);
  top: 524px;
  width: 675px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    text-align: left;
    font: 500 16px/23.52px 'RoobertRegular', sans-serif;
    position: absolute;
    left: 42px;
    top: 220px;
    width: 213px;
    height: 19px;
  }
}
.row-bg-0 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 588px;
}
.row-bg-1 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 718px;
}
.row-bg-2 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 848px;
}
.row-bg-3 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 978px;
}
.row-bg-4 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1108px;
}
.row-bg-5 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1238px;
}
.row-bg-6 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1368px;
}
.row-bg-7 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1498px;
}
.row-bg-8 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1628px;
}
.row-bg-9 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1758px;
}
.row-tier-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 624px;
  width: 154px;
}
.row-tier-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 754px;
  width: 185px;
}
.row-tier-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 884px;
  width: 185px;
}
.row-tier-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1014px;
  width: 185px;
}
.row-tier-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1144px;
  width: 185px;
}
.row-tier-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1274px;
  width: 185px;
}
.row-tier-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1404px;
  width: 185px;
}
.row-tier-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1534px;
  width: 185px;
}
.row-tier-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1664px;
  width: 185px;
}
.row-tier-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1794px;
  width: 185px;
}
.row-code-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 624px;
  width: 250px;
  z-index: 1;
}
.row-code-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 754px;
  width: 250px;
  height: 39px;
  z-index: 1;
}
.row-code-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 625px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 754px;
  width: 250px;
  height: 27px;
  z-index: 1;
}
.row-name-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 625px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 754px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 881px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1015px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1145px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1276px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1405px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1535px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1665px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1795px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.rewards-table-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.row-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.row-bg-mobile {
  background: #111111;
  border-radius: 10px;
  width: 306px;
  height: 132px;
  position: absolute;
  left: 43px;
  top: 232px;
}
.row-container-mobile {
  width: 281px;
  height: 97px;
  position: static;
}
.row-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 302px;
  width: 141px;
}
.row-rewards-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 304px;
  width: 127px;
}
.row-tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 253px;
  width: 90px;
  height: 21px;
}
.row-tier-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 272px;
  width: 90px;
  height: 21px;
}
.row-name-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 329px;
  width: 138px;
  height: 21px;
}
.row-coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 224px;
  top: 253px;
  width: 127px;
  height: 22px;
}
.row-code-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 272px;
  width: 120px;
  height: 22px;
}
.row-mechanics-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 324px;
  width: 120px;
  height: 22px;
}
</style>
